import React, { useState } from "react";
import PortfolioHeading from "../../components/PortfolioHeading";
import ProjectCard from "../../components/ProjectCard";
import Footer from "../../components/Footer";
import Banner2 from "../../components/Banner2";
import { useLanguage } from "../../LanguageContext";
import "./Portfolios.css";

function Portfolios() {
    const [tab, setTab] = useState(1);
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            categories: ["All", "Web Development", "App Development"],
            data: [
                {
                    id: 1,
                    item: "WD",
                    img: "imgs/halloween.png",
                    heading: "Halloween Event Website (Demo)",
                    text: "Explore the spooky design and interactive features that capture the essence of Halloween in this dedicated event website.",
                    link: "https://halloween-main.vercel.app/",
                },
                {
                    id: 2,
                    item: "WD",
                    img: "imgs/plant.png",
                    heading: "Plants Website (Demo)",
                    text: "Discover how to create engaging layouts and interactive features for a botanical-themed website.",
                    link: "https://plants-website-topaz.vercel.app/",
                },
                {
                    id: 3,
                    item: "WD",
                    img: "imgs/delivery.png",
                    heading: "Delivery Website (Demo)",
                    text: "Explore the core functionalities that make a delivery service website efficient and user-friendly.",
                    link: "https://delivery-website-2t7zaot6x-thearnabsaha.vercel.app/",
                },
                {
                    id: 4,
                    item: "WD",
                    img: "imgs/headphone.png",
                    heading: "Headphones E-Commerce (Demo)",
                    text: "Learn about the key design and functionality elements that enhance user experience on an e-commerce site for headphones.",
                    link: "https://headphones-website-iota.vercel.app/",
                },
                {
                    id: 5,
                    item: "WD",
                    img: "imgs/travel.png",
                    heading: "Travel Website (Demo)",
                    text: "Explore the features of this beautiful travel website, designed to enhance user experience and offer seamless navigation.",
                    link: "https://tarvel-website-lemon.vercel.app/",
                },
                {
                    id: 6,
                    item: "WD",
                    img: "imgs/restaurant.png",
                    heading: "Restaurant Website (Demo)",
                    text: "Dive into the flavorful world of culinary arts with this restaurant website showcasing menu design, booking systems, and user engagement features.",
                    link: "https://restaurant-main-five.vercel.app/",
                },
                {
                    id: 7,
                    item: "AD",
                    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx3qEToYKdCNo7h7WiEc191etn-rxUBm6cXw&usqp=CAU",
                    heading: "ReactNative or Flutter",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores nisi ipsam porro, corrupti consectetur voluptas?",
                    link: "#",
                },
                {
                    id: 8,
                    item: "AD",
                    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx3qEToYKdCNo7h7WiEc191etn-rxUBm6cXw&usqp=CAU",
                    heading: "ReactNative or Flutter",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores nisi ipsam porro, corrupti consectetur voluptas?",
                    link: "#",
                }
            ]
        },
        de: {
            categories: ["Alle", "Webentwicklung", "App-Entwicklung"],
            data: [
                {
                    id: 1,
                    item: "WD",
                    img: "imgs/travel.png",
                    heading: "Reisewebseite (Demo)",
                    text: "Erkunden Sie die Funktionen dieser schönen Reisewebseite, die darauf ausgelegt ist, das Benutzererlebnis zu verbessern und eine nahtlose Navigation zu bieten.",
                    link: "https://tarvel-website-lemon.vercel.app/",
                },
                {
                    id: 2,
                    item: "WD",
                    img: "imgs/plant.png",
                    heading: "Pflanzenwebseite (Demo)",
                    text: "Entdecken Sie, wie Sie ansprechende Layouts und interaktive Funktionen für eine botanisch thematisierte Webseite erstellen.",
                    link: "https://plants-website-topaz.vercel.app/",
                },
                {
                    id: 3,
                    item: "WD",
                    img: "imgs/delivery.png",
                    heading: "Lieferdienst-Webseite (Demo)",
                    text: "Erforschen Sie die Kernfunktionen, die eine Webseite für Lieferdienste effizient und benutzerfreundlich machen.",
                    link: "https://delivery-website-2t7zaot6x-thearnabsaha.vercel.app/",
                },
                {
                    id: 4,
                    item: "WD",
                    img: "imgs/headphone.png",
                    heading: "Kopfhörer-E-Commerce (Demo)",
                    text: "Erfahren Sie mehr über die wichtigen Design- und Funktionsmerkmale, die das Benutzererlebnis auf einer E-Commerce-Seite für Kopfhörer verbessern.",
                    link: "https://headphones-website-iota.vercel.app/",
                },
                {
                    id: 5,
                    item: "WD",
                    img: "imgs/halloween.png",
                    heading: "Halloween-Eventwebseite (Demo)",
                    text: "Erkunden Sie das gruselige Design und die interaktiven Funktionen, die das Wesen von Halloween auf dieser speziellen Veranstaltungswebseite einfangen.",
                    link: "https://halloween-main.vercel.app/",
                },
                {
                    id: 6,
                    item: "WD",
                    img: "imgs/restaurant.png",
                    heading: "Restaurantwebseite (Demo)",
                    text: "Tauchen Sie ein in die geschmackvolle Welt der kulinarischen Künste mit dieser Restaurantwebseite, die Menüdesign, Buchungssysteme und Benutzerinteraktionsfunktionen zeigt.",
                    link: "https://restaurant-main-five.vercel.app/",
                },
                {
                    id: 7,
                    item: "AD",
                    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx3qEToYKdCNo7h7WiEc191etn-rxUBm6cXw&usqp=CAU",
                    heading: "ReactNative oder Flutter",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores nisi ipsam porro, corrupti consectetur voluptas?",
                    link: "#",
                },
                {
                    id: 8,
                    item: "AD",
                    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx3qEToYKdCNo7h7WiEc191etn-rxUBm6cXw&usqp=CAU",
                    heading: "ReactNative oder Flutter",
                    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores nisi ipsam porro, corrupti consectetur voluptas?",
                    link: "#",
                }
            ]
        }
    };

    const { categories, data: projectData } = translations[language];

    function activeTab(tabNumber) {
        setTab(tabNumber);
    }

    const displayData = tab === 1 ? projectData : projectData.filter((project) => {
        if (tab === 2 && project.item === "WD") return true;
        if (tab === 3 && project.item === "AD") return true;
        return false;
    });

    return (
        <>
            <div className="container">
                <PortfolioHeading />
                <div className="category">
                    {categories.map((category, index) => (
                        <div key={index} onClick={() => activeTab(index + 1)} 
                             style={{ background: tab === index + 1 ? "#423EFE" : "", 
                                      color: tab === index + 1 ? "white" : "" }}>
                            {category}
                        </div>
                    ))}
                </div>
                <div className="items">
                    {displayData.map((project) => (
                        <ProjectCard
                            key={project.id}
                            img={project.img}
                            heading={project.heading}
                            text={project.text}
                            link={project.link}
                        />
                    ))}
                </div>
            </div>
            <Banner2 />
            <Footer />
        </>
    );
}

export default Portfolios;
