import React from "react";
import { useLanguage } from "../LanguageContext"; // Ensure this path is correct

function PortfolioHeading() {
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            title: "Discover the works done by us!",
            description: "Explore our curated projects showcasing innovation, creativity, and precision."
        },
        de: {
            title: "Entdecken Sie unsere Arbeiten!",
            description: "Erkunden Sie unsere ausgewählten Projekte, die Innovation, Kreativität und Präzision demonstrieren."
        }
    };

    return (
        <div className="port-heading">
            <h1>{translations[language].title}</h1>
            <p>{translations[language].description}</p>
        </div>
    );
};

export default PortfolioHeading;
