import React from "react";
import { useLanguage } from "../LanguageContext"; // Ensure this path is correct

function Banner2() {
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            headline: "Have An Idea In Mind? Let’s Work Together.",
            cta: "Say Hello"
        },
        de: {
            headline: "Haben Sie eine Idee im Kopf? Lassen Sie uns zusammenarbeiten.",
            cta: "Sagen Sie Hallo"
        }
    };

    return (
        <div className="banner__1 banner2">
            <div className="banner__text banner2__text">
                <h2>{translations[language].headline}</h2>
                <div className="banner__text banner2__btn">
                    <a className="SayHello banner2SH" href="https://wa.me/6296818891">{translations[language].cta}</a>
                </div>
            </div>
            
            <img className="imgB" src="../images/banner2.png" alt="Banner image" />
            <img className="imgS" src="../images/banner2m.png" alt="Mobile banner image" />
        </div>
    );
}

export default Banner2;

