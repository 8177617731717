import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext"; // Make sure the path is correct
import "../pages/Home/Home.css"; // Ensure this path matches the location of your CSS

function MainHeader() {
    const { language } = useLanguage(); // Use the language context to get the current language

    // Translations object
    const translations = {
        en: {
            title: "We Combine Code with Design to Craft your Online Business",
            subtitle: "Helping Brands and Business to Create Apps and Website with our Expertise",
            sayHello: "SAY HELLO",
            seeOurWorks: "See our Works"
        },
        de: {
            title: "Wir verbinden Code mit Design, um Ihr Online-Geschäft zu gestalten",
            subtitle: "Wir helfen Marken und Unternehmen, Apps und Websites mit unserer Expertise zu erstellen",
            sayHello: "SAG HALLO",
            seeOurWorks: "Sehen Sie unsere Arbeiten"
        }
    };

    // Function to handle WhatsApp redirection
    const whatsapp = () => {
        window.location.href = "https://wa.me/6296818891"
    };

    return (
        <header className="main__header">
            <div className="container main__header-container">
                <div className="main__header-left">
                    <h1>{translations[language].title} <span>Craft</span></h1>
                    <p>{translations[language].subtitle}</p>
                    <div className="main__header-btns">
                        <button className="btn SH" onClick={whatsapp}>{translations[language].sayHello}</button>
                        <Link to="/portfolios" className="btn SW">{translations[language].seeOurWorks}</Link>
                    </div>
                </div>
                <div className="main__header-right">
                    <div className="main__header-sparkle">
                        <img src="../images/sparkle.png" alt="sparkle-img" />
                    </div>
                    <div className="main__header-images">
                        <div className="main__header-images-left">
                            <img src="../images/Header_img1.png" alt="Header-img1" />
                            <img src="../images/Header_img3.png" alt="Header-img3" />
                        </div>
                        <div className="main__header-images-right">
                            <img src="../images/Header_img2.png" alt="Header-img2" />
                            <img src="../images/Header_img4.png" alt="Header-img4" />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default MainHeader;

