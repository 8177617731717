const cdata=[
    {
        id:1,
        heading:"Admin Dashboard For Dora",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:2,
        heading:"Admin Dashboard For Harry",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:3,
        heading:"Admin Dashboard For Jhon",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:4,
        heading:"Admin Dashboard For Jane",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:5,
        heading:"Admin Dashboard For Prince",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:6,
        heading:"Features of HTML",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:7,
        heading:"ReactNative or Flutter",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:8,
        heading:"How to design in Figma",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:9,
        heading:"Features of HTML",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:10,
        heading:"ReactNative or Flutter",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
    {
        id:11,
        heading:"How to design in Figma",
        passage:"this is home1",
        imgH:"images/case1iii.jpg",
        img1:"images/case2.png",
        img2:"images/case3.png",
        img3:"images/case4.png",
    },
];
//1-5: for Home 
//6-11: for Portfolios
export {cdata};