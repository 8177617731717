import React from "react";
import { useLanguage } from "../LanguageContext"; // Make sure the path is correct
import WhatWeDoCard from "./WhatWeDoCard";
import "../index.css";

const translations = {
    en: {
        wwd_content: [
            { id: "1", heading: "Web Development", text: "Custom, responsive websites tailored to your business, enhancing engagement and boosting conversions." },
            { id: "2", heading: "Android App Development", text: "Design intuitive, feature-rich Android apps optimized for performance and seamless user experience." },
            { id: "3", heading: "Cross-Platform Web Development", text: "Build cross-platform applications that work flawlessly, expanding reach and maximizing impact." },
            { id: "4", heading: "Blockchain Development", text: "Develop secure, decentralized apps with blockchain technology, enhancing transparency and driving innovation." },
            { id: "5", heading: "Marketing", text: "Boost your brand with digital strategies, fostering audience engagement and business growth." },
        ],
        whatWeDo: "What We Do",
        slogan: "We Bring Happiness using Services",
        sayHello: "Say Hello"
    },
    de: {
        wwd_content: [
            { id: "1", heading: "Webentwicklung", text: "Maßgeschneiderte, responsive Websites, die auf Ihr Unternehmen zugeschnitten sind, verbessern das Engagement und erhöhen die Konversionen." },
            { id: "2", heading: "Android App-Entwicklung", text: "Entwerfen Sie intuitive, leistungsstarke Android-Apps, die für Leistung und nahtloses Benutzererlebnis optimiert sind." },
            { id: "3", heading: "Cross-Platform Webentwicklung", text: "Erstellen Sie plattformübergreifende Anwendungen, die fehlerfrei funktionieren, die Reichweite erweitern und die Wirkung maximieren." },
            { id: "4", heading: "Blockchain-Entwicklung", text: "Entwickeln Sie sichere, dezentralisierte Anwendungen mit Blockchain-Technologie, die Transparenz erhöhen und Innovationen vorantreiben." },
            { id: "5", heading: "Marketing", text: "Steigern Sie Ihre Marke mit digitalen Strategien, die das Engagement des Publikums fördern und das Geschäftswachstum unterstützen." },
        ],
        whatWeDo: "Was wir tun",
        slogan: "Wir bringen Glück mit unseren Dienstleistungen",
        sayHello: "Sagen Sie Hallo"
    }
};

function WhatWeDo(){
    const { language } = useLanguage(); // Access the current language

    const WWDW = translations[language].wwd_content.map((data) => {
        return <WhatWeDoCard  
                    key={data.id}                  
                    heading={data.heading}
                    text={data.text}
                />;
    });

    return (
        <div className="container wdc" style={{justifyContent:"center", display:"flex", marginTop:"7vw"}}>
            <div className="project" >
                <div className="project-card-wwd" >
                    <div className="wwd__div">
                        <p>{translations[language].whatWeDo}</p>
                        <h3>{translations[language].slogan}</h3>
                        <a className="SayHello" href="https://wa.me/6296818891">{translations[language].sayHello}</a>
                    </div>
                    {WWDW}
                </div>
            </div>
        </div>
    );
}

export default WhatWeDo;
