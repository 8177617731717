import React from "react";
import { useLanguage } from "../../LanguageContext";
import Banner2 from "../../components/Banner2";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import OverlayImageBanner from "../../components/OverlayImageBanner";
import TeamCard from "../../components/TeamCard";
import WpCards from "../../components/WpCards";
import ZigZagCard from "../../components/ZigZagCard";
import "./About.css";

function About() {
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            aboutHeading: "We are Grevelops",
            aboutSubheading: "At Grevelops, we are passionate about pushing the boundaries of web and App development and staying ahead of the latest trends and technologies.",
            overlayText: "A TEAM THAT ALWAYS FOCUSES ON YOUR GROWTH",
            workProcess: "Work Process",
            workProcessSubtitle: "Our process to build our product",
            teamTitle: "Team",
            teamSubtitle: "Meet the team behind Grevelops",
            wp__content: [
                {
                    index: "01",
                    h4: "Research",
                    p: "We analyze goals and market needs to guide design strategy.",
                },
                {
                    index: "02",
                    h4: "Build",
                    p: "Our team develops tailored solutions using the latest technologies.",
                },
                {
                    index: "03",
                    h4: "Deploy",
                    p: "We launch your product seamlessly with rigorous performance testing.",
                },
                {
                    index: "04",
                    h4: "Maintenance",
                    p: "We provide ongoing support to keep your product secure and updated.",
                },
            ],
            team__content: [
                {
                    id: "1",
                    img: "../imgs/arnab.jpeg",
                    name: "Arnab Saha",
                    pos: "Co-founder & CEO",
                    linkedin: "https://www.linkedin.com/in/thearnabsaha/",
                    twitter: "https://x.com/TheArnabSaha",
                },
                {
                    id: "2",
                    img: "../imgs/ankush.jpg",
                    name: "Ankush Das",
                    pos: "Co-founder & CTO",
                    linkedin: "https://www.linkedin.com/in/anounman/",
                    twitter: "https://x.com/anounman",
                },
            ],
            zzc__content: [
                {
                    id: "1",
                    order: "0",
                    img: "../images/zzc1.png",
                    sub: "Our Mission",
                    heading: "Building Better Digital Products for the Future",
                    txt: "At Grevelops, our mission is to empower businesses through innovative technology solutions, fostering growth and efficiency while delivering exceptional value and outstanding user experiences for our clients.",
                },
                {
                    id: "2",
                    order: "1", // reverse
                    img: "../images/zzc2.png",
                    sub: "Our Story",
                    heading: "Building Better Digital Products for the Future",
                    txt: "Founded with a vision to transform ideas into reality, Grevelops has evolved from a small startup into a trusted tech partner, dedicated to innovation and excellence in every project.",
                },
            ]
        },
        de: {
            aboutHeading: "Wir sind Grevelops",
            aboutSubheading: "Bei Grevelops sind wir leidenschaftlich darum bemüht, die Grenzen der Web- und App-Entwicklung zu erweitern und immer am Puls der neuesten Trends und Technologien zu sein.",
            overlayText: "EIN TEAM, DAS IMMER AUF IHR WACHSTUM FOKUSSIERT IST",
            workProcess: "Arbeitsprozess",
            workProcessSubtitle: "Unser Prozess, um unser Produkt zu bauen",
            teamTitle: "Team",
            teamSubtitle: "Treffen Sie das Team hinter Grevelops",
            wp__content: [
                {
                    index: "01",
                    h4: "Forschung",
                    p: "Wir analysieren Ziele und Marktanforderungen, um die Designstrategie zu leiten.",
                },
                {
                    index: "02",
                    h4: "Entwicklung",
                    p: "Unser Team entwickelt maßgeschneiderte Lösungen mit den neuesten Technologien.",
                },
                {
                    index: "03",
                    h4: "Einsatz",
                    p: "Wir starten Ihr Produkt nahtlos mit rigorosen Leistungstests.",
                },
                {
                    index: "04",
                    h4: "Wartung",
                    p: "Wir bieten kontinuierliche Unterstützung, um Ihr Produkt sicher und aktuell zu halten.",
                },
            ],
            team__content: [
                {
                    id: "1",
                    img: "../imgs/arnab.jpeg",
                    name: "Arnab Saha",
                    pos: "Mitgründer & CEO",
                    linkedin: "https://www.linkedin.com/in/thearnabsaha/",
                    twitter: "https://x.com/TheArnabSaha",
                },
                {
                    id: "2",
                    img: "../imgs/ankush.jpg",
                    name: "Ankush Das",
                    pos: "Mitgründer & CTO",
                    linkedin: "https://www.linkedin.com/in/anounman/",
                    twitter: "https://x.com/anounman",
                },
            ],
            zzc__content: [
                {
                    id: "1",
                    order: "0",
                    img: "../images/zzc1.png",
                    sub: "Unsere Mission",
                    heading: "Bessere digitale Produkte für die Zukunft bauen",
                    txt: "Bei Grevelops ist es unsere Mission, Unternehmen durch innovative Technologielösungen zu stärken, Wachstum und Effizienz zu fördern, während wir außergewöhnlichen Wert und herausragende Benutzererlebnisse für unsere Kunden liefern.",
                },
                {
                    id: "2",
                    order: "1", // reverse
                    img: "../images/zzc2.png",
                    sub: "Unsere Geschichte",
                    heading: "Bessere digitale Produkte für die Zukunft bauen",
                    txt: "Gegründet mit der Vision, Ideen in Realität zu verwandeln, hat sich Grevelops von einem kleinen Startup zu einem vertrauenswürdigen Technologiepartner entwickelt, der sich jedem Projekt mit Innovation und Exzellenz widmet.",
                },
            ]
        }
    };

    const WP = translations[language].wp__content.map((data) => {
        return <WpCards 
                    index={data.index}
                    key={data.index}
                    h4={data.h4}
                    p={data.p}
                />;
    });

    const TC = translations[language].team__content.map((data) => {
        return <TeamCard 
                    key={data.id}
                    image={data.img}
                    name={data.name}
                    pos={data.pos}
                    L_link={data.linkedin}
                    T_link={data.twitter}
                />;
    });

    const ZZC = translations[language].zzc__content.map((data) => {
        return <ZigZagCard 
                    key={data.id}
                    order={data.order}
                    img={data.img}
                    sub={data.sub}
                    heading={data.heading}
                    txt={data.txt}
                />;
    });

    return (
        <div className="about__container">
            <div className="about__heading">
                <p>{translations[language].aboutHeading}</p>
                <h4>{translations[language].aboutSubheading}</h4>
            </div>
            <OverlayImageBanner 
                img1="../images/oibImg1c.png" 
                img2="../images/oibImg1.png" 
                txt={translations[language].overlayText}
            />
            <div className="zzc_container">{ZZC}</div>
            <Heading 
                p={translations[language].workProcess}
                h2={translations[language].workProcessSubtitle}
            />
            <div className="wpCards__container">{WP}</div>            
            <Heading 
                p={translations[language].teamTitle}
                h2={translations[language].teamSubtitle}
            />
            <div className="teamCards__container">{TC}</div>
            <Banner2 />
            <Footer/>
        </div>
    );
}

export default About;
