import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const TermsAndConditions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const termsData = [
    {
      title: "Introduction",
      content:
        "Welcome to Grevelops! These Terms and Conditions govern your use of our services. By accessing our website or using our services, you agree to abide by these terms. If you do not agree with any part of these terms, you must stop using our services immediately."
    },
    {
      title: "Intellectual Property Rights",
      content:
        "Grevelops owns all intellectual property rights related to the content on this site, including but not limited to text, images, logos, and code. You may not use, reproduce, or distribute any content without prior written consent from Grevelops."
    },
    {
      title: "User Obligations",
      content:
        "As a user of our services, you are responsible for providing accurate information and for the security of your account credentials. You agree not to misuse the services or infringe on the rights of others while using Grevelops."
    },
    {
      title: "Payment Terms",
      content:
        "All payments for services provided by Grevelops are due upon receipt of the invoice. Late payments may result in additional fees or service suspensions. Please review our payment policy for further details."
    },
    {
      title: "Service Availability",
      content:
        "We strive to ensure that our services are available at all times. However, there may be instances where services are temporarily unavailable due to maintenance or unforeseen circumstances. Grevelops is not liable for any downtime or service interruptions."
    },
    {
      title: "Limitations of Liability",
      content:
        "Grevelops is not liable for any direct, indirect, incidental, or consequential damages arising out of the use of our services. This includes, but is not limited to, data loss, loss of business, or any other damages resulting from service issues."
    },
    {
      title: "Termination of Services",
      content:
        "We reserve the right to terminate your access to our services if you breach these Terms and Conditions. Termination may occur with or without prior notice, and you will remain responsible for any outstanding payments."
    },
    {
      title: "Governing Law",
      content:
        "These terms are governed by the laws of India. Any disputes arising from or related to the use of our services will be subject to the exclusive jurisdiction of the courts in India."
    },
    {
      title: "Changes to Terms",
      content:
        "Grevelops reserves the right to modify or update these Terms and Conditions at any time. Changes will be posted on our website, and it is your responsibility to review the terms regularly."
    },
    {
      title: "Contact Information",
      content:
        "If you have any questions regarding these Terms and Conditions, please contact us via email or phone. Our team will be happy to assist you."
    }
  ];

  return (
    <div style={styles.container}>
      <h1 style={styles.mainHeading}>Grevelops Terms and Conditions</h1>
      <p style={styles.description}>
        Please read the following terms and conditions carefully before using our website and services. By accessing or using our services, you agree to be bound by the terms and conditions described here.
      </p>
      {termsData.map((term, index) => (
        <div key={index} style={styles.termItem}>
          <div
            style={styles.termTitle}
            onClick={() => toggleAccordion(index)}
          >
            <span>{term.title}</span>
            <span style={styles.icon}>
              {activeIndex === index ? <FiChevronUp /> : <FiChevronDown />}
            </span>
          </div>
          <div
            style={{
              ...styles.termContent,
              display: activeIndex === index ? 'block' : 'none',
            }}
          >
            {term.content}
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '800px',
    margin: '10rem auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  mainHeading: {
    textAlign: 'center',
    marginBottom: '10px',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#333',
  },
  description: {
    textAlign: 'center',
    marginBottom: '30px',
    fontSize: '1.1rem',
    color: '#666',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#333',
  },
  termItem: {
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  termTitle: {
    backgroundColor: '#f9f9f9',
    padding: '15px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: '#333',
  },
  termContent: {
    backgroundColor: '#fff',
    padding: '15px',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#666',
  },
  icon: {
    fontSize: '1.5rem',
    color: '#333',
  },
};

export default TermsAndConditions;
