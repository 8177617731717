import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext"; // Ensure this path is correct

function Footer() {
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            agencyDescription: "An agency that helps businesses create better apps and websites with modern technology.",
            about: "About",
            ourMission: "Our Mission",
            faqs: "FAQ's",
            termsAndPolicy: "Terms and Policy",
            followUs: "Follow Us",
            copyright: "Copyright &copy; Grevelops 2023. All rights reserved."
        },
        de: {
            agencyDescription: "Eine Agentur, die Unternehmen hilft, bessere Apps und Websites mit moderner Technologie zu erstellen.",
            about: "Über uns",
            ourMission: "Unsere Mission",
            faqs: "FAQs",
            termsAndPolicy: "Nutzungsbedingungen und Datenschutz",
            followUs: "Folgen Sie uns",
            copyright: "Urheberrecht &copy; Grevelops 2023. Alle Rechte vorbehalten."
        }
    };

    const goToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <footer>
            <div className="footer__container">
                <article>
                    <Link to="/" className="logo" onClick={goToTop}>
                        <p>Grevelops</p>
                    </Link>
                    <p className="textUnderLogo">{translations[language].agencyDescription}</p>
                </article>
                <article>
                    <h4>{translations[language].about}</h4>
                    <Link to="/about" onClick={goToTop}>{translations[language].ourMission}</Link>
                </article>
                <article>
                    <Link to="/faq" onClick={goToTop}>{translations[language].faqs}</Link>
                    <Link to="/terms-and-conditions" onClick={goToTop}>{translations[language].termsAndPolicy}</Link>
                </article>
                <article>
                    <h4>{translations[language].followUs}</h4>
                    <a href="https://www.linkedin.com/company/89839888/admin/dashboard/">LinkedIn</a>
                    <a href="https://x.com/grevelops">Twitter</a>
                </article>
            </div>
            <div className="footer__copyright">
                <small dangerouslySetInnerHTML={{__html: translations[language].copyright}}></small>
            </div>
        </footer>
    );
}

export default Footer;
