import React from "react";
import { useLanguage } from "../LanguageContext"; // Ensure this path is correct

function Banner1() {
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            text: "We Combine Code With Design To Craft Your Online Business"
        },
        de: {
            text: "Wir verbinden Code mit Design, um Ihr Online-Geschäft zu gestalten"
        }
    };

    return (
        <div className="banner__1">
            <div className="banner__text">
                <h2>{translations[language].text}</h2>
            </div>
            <img className="imgB" src="../images/banner1.png" alt="Banner image" />
            <img className="imgS" src="../images/banner1m.png" alt="Mobile banner image" />
        </div>
    );
}

export default Banner1;
