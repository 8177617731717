import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Grevelops?",
      answer: "Grevelops is a tech solutions software agency based out of India, offering innovative and customized solutions to clients worldwide."
    },
    {
      question: "What services do you provide?",
      answer: "We provide services in software development, cloud solutions, AI & ML development, mobile apps, and much more."
    },
    {
      question: "How can I get in touch?",
      answer: "You can reach out to us through our contact page, email, or phone number provided on the website."
    },
    {
      question: "What technologies do you specialize in?",
      answer: "We specialize in technologies like React, Node.js, Python, and more to provide robust and scalable solutions."
    },
    {
      question: "What is your typical project timeline?",
      answer: "Our typical project timeline varies depending on the scope of the project but usually ranges from 2-6 months."
    },
    {
      question: "How much do your services cost?",
      answer: "Our pricing is customized according to the client's requirements and project scope. Please contact us for more details."
    },
    {
      question: "What industries do you work with?",
      answer: "We work across multiple industries, including fintech, e-commerce, healthcare, and education, among others."
    },
    {
      question: "Do you provide after-launch support?",
      answer: "Yes, we provide ongoing support and maintenance after the launch of the product."
    },
    {
      question: "Do you offer cloud solutions?",
      answer: "Yes, we offer cloud integration, migration, and management services."
    },
    {
      question: "What is your experience with AI and ML?",
      answer: "Our team has extensive experience in AI and machine learning, providing solutions like predictive analysis, data processing, and more."
    },
    {
      question: "Can you work with international clients?",
      answer: "Yes, we have experience working with clients across the globe, from startups to large enterprises."
    },
    {
      question: "How can I get a project quote?",
      answer: "You can contact us via our website with your project details, and we will get back to you with a customized quote."
    },
    {
      question: "What makes your agency unique?",
      answer: "We combine deep technical expertise with a design-first approach, offering scalable and user-centered solutions."
    },
    {
      question: "Do you offer mobile app development?",
      answer: "Yes, we specialize in developing mobile apps for both iOS and Android platforms."
    },
    {
      question: "What is your approach to project management?",
      answer: "We use Agile methodology to ensure flexibility and iterative progress throughout the project lifecycle."
    },
    {
      question: "Do you offer UI/UX design services?",
      answer: "Yes, we provide comprehensive UI/UX design services to create user-friendly and aesthetically pleasing applications."
    },
    {
      question: "Can I see some of your past work?",
      answer: "Yes, you can visit our portfolio on our website to see some of the projects we have worked on."
    },
    {
      question: "How do you ensure quality?",
      answer: "We follow a strict QA process that includes manual and automated testing to ensure high-quality output."
    },
    {
      question: "What tools do you use?",
      answer: "We use tools like React, Node.js, AWS, Figma, and others depending on the project requirements."
    },
    {
      question: "Do you provide SEO services?",
      answer: "Yes, we offer SEO and performance optimization services for websites."
    }
  ];

  return (
    <div style={styles.faqContainer}>
      <h2 style={styles.title}>Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <div key={index} style={styles.faqItem}>
          <div
            style={styles.faqQuestion}
            onClick={() => toggleAccordion(index)}
          >
            <span>{faq.question}</span>
            <span style={styles.icon}>
              {activeIndex === index ? <FiChevronUp /> : <FiChevronDown />}
            </span>
          </div>
          <div
            style={{
              ...styles.faqAnswer,
              display: activeIndex === index ? 'block' : 'none',
            }}
          >
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  faqContainer: {
    width: '100%',
    maxWidth: '800px',
    margin: '10rem auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#333',
  },
  faqItem: {
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  faqQuestion: {
    backgroundColor: '#f9f9f9',
    padding: '15px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    color: '#333',
  },
  faqAnswer: {
    backgroundColor: '#fff',
    padding: '15px',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#666',
  },
  icon: {
    fontSize: '1.5rem',
    color: '#333',
  },
};

export default Faq;
