// Assuming LanguageContext and Card1, Banner2, Footer, OverlayImageBanner are imported correctly
import React from "react";
import { useLanguage } from "../../LanguageContext"; // Ensure this path is correct
import Card1 from "../../components/Card1";
import Banner2 from "../../components/Banner2";
// import Card1 from "../../components/Card1";
import Footer from "../../components/Footer";
import OverlayImageBanner from "../../components/OverlayImageBanner";
import "./Contact.css";

function Contact() {
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            contactOptions: [
                {
                    id: 1,
                    heading: "Call Our Expert",
                    txt: "You can call and talk to Our Expert at 6296818891",
                    btn: "Call Now",
                    link: "tel:6296818891",
                    green: "no",
                },
                {
                    id: 2,
                    heading: "Message us",
                    txt: "Chat with our WhatsApp Assistant immediately or leave a message",
                    btn: "Say Hello!",
                    link: "https://wa.me/6296818891",
                    green: "yes",
                },
                {
                    id: 3,
                    heading: "Send an Email",
                    txt: "Prefer to mail? Send us an email we will get back to you soon ",
                    btn: "Send email",
                    link: "mailto:your@email.com?subject=Hello%20Arnab!",
                    green: "no",
                },
            ],
            overlayText: "WE ARE GREVELOPS, LET'S TALK",
            heading: "Want to know more? Reach out to us",
            getInTouchHeading: "Get in Touch with us!",
            officeName: "Grevelopers HQ",
            address: "AK-2, AK Block, Newtown, West Bengal, India, 700156",
            phoneLabel: "Phone:",
            emailLabel: "Email:",
            email: "contact@grevelops.co",
        },
        de: {
            contactOptions: [
                {
                    id: 1,
                    heading: "Rufen Sie unseren Experten an",
                    txt: "Sie können unseren Experten unter 6296818891 anrufen und sprechen",
                    btn: "Jetzt anrufen",
                    link: "tel:6296818891",
                    green: "no",
                },
                {
                    id: 2,
                    heading: "Nachricht senden",
                    txt: "Chatten Sie sofort mit unserem WhatsApp-Assistenten oder hinterlassen Sie eine Nachricht",
                    btn: "Sag Hallo!",
                    link: "https://wa.me/6296818891",
                    green: "yes",
                },
                {
                    id: 3,
                    heading: "Eine E-Mail senden",
                    txt: "Bevorzugen Sie E-Mail? Senden Sie uns eine E-Mail, wir werden Ihnen bald antworten",
                    btn: "E-Mail senden",
                    link: "mailto:your@email.com?subject=Hallo%20Arnab!",
                    green: "no",
                },
            ],
            overlayText: "WIR SIND GREVELOPS, LASSEN SIE UNS SPRECHEN",
            heading: "Möchten Sie mehr erfahren? Kontaktieren Sie uns",
            getInTouchHeading: "Nehmen Sie Kontakt mit uns auf!",
            officeName: "Grevelopers HQ",
            address: "AK-2, AK Block, Newtown, West Bengal, Indien, 700156",
            phoneLabel: "Telefon:",
            emailLabel: "E-Mail:",
            email: "contact@grevelops.co",
        }
    };

    const contactInfo = translations[language];

    return (
        <div className="contact__container">
            <OverlayImageBanner 
                img1="../images/oibImg2c.png" 
                img2="../images/oibImg2.png" 
                txt={contactInfo.overlayText}
            />
            <div className="contact__heading">
                <h3>{contactInfo.heading}</h3>
            </div>
            <div className="contact__cards">
                {contactInfo.contactOptions.map((option) => (
                    <Card1
                        key={option.id}
                        heading={option.heading}
                        txt={option.txt}
                        btn={option.btn}
                        link={option.link}
                        green={option.green}
                    />
                ))}
            </div>
            <div className="contact__get_in_touch">
                <div className="git__content">
                    <h4>{contactInfo.getInTouchHeading}</h4>
                    <h3>{contactInfo.officeName}</h3>
                    <p className="address">{contactInfo.address}</p>
                    <p className="ph">{contactInfo.phoneLabel} <a href={`tel:${contactInfo.phone}`}>+91 6296818891</a></p>
                    <p className="email">{contactInfo.emailLabel} <a href={`mailto:${contactInfo.email}?subject=Hello%20Arnab!`}>{contactInfo.email}</a></p>
                </div>
                <img src="../images/GetInTouch2.jpg" alt="Grevelopers HQ" />
            </div>
            <Banner2 />
            <Footer />
        </div>
    );
}

export default Contact;

