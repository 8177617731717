import React from 'react';
import "../index.css";
import { useLanguage } from "../LanguageContext"; // Ensure this path is correct

const divStyle = {
    width: "28vw",
    height: "auto",
    flexShrink: 0,
    backgroundColor: "white",    
    borderRadius: "10px",
    paddingTop: "1rem",
};
const imgStyle = {
    width: "25vw",
    margin: "0.8rem auto",
    marginTop: "0",
    borderRadius: "10px",
};
const headingStyle = {
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "1rem",
    fontWeight: "500",
};
const textStyle = {
    fontSize: "1rem",
    color: "rgba(23, 23, 23, 0.60)",
    margin: "1rem 1.3rem",
};
const buttonStyle = {
    fontSize: "1rem",
    color: "#423efe",
    padding: "0.5rem 2rem",
    border: "1px solid #423efe",
    borderRadius: "3rem",
};

function ProjectCard({ img, heading, text, button,link }) {
    const { language } = useLanguage(); // Use the language context to get the current language

    const translations = {
        en: {
            readCaseStudy: "Read Case Study"
        },
        de: {
            readCaseStudy: "Fallstudie lesen"
        }
    };

    const goToTop = () => {        
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <div className='card-component' style={divStyle}>
            <img style={imgStyle} src={img} alt="projectCardImg" />
            <h4 style={headingStyle}>{heading}</h4>
            <p style={textStyle}>{text}</p>
            {/* <p style={textStyle}>{link}</p> */}
            <div style={{display: "flex", justifyContent: "center"}}>
                <a href={link}
                   style={buttonStyle}
                   onClick={goToTop}
                   target="_blank" // Open in a new tab
                   rel="noopener noreferrer" // Security measure for opening links in a new tab
                >
                  {translations[language].readCaseStudy}
                </a>    
            </div>
            <br></br>
        </div>
    );
};

export default ProjectCard;
