import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext"; // Ensure this path is correct
import ProjectCard from "./ProjectCard";
import "../index.css";

const project_content = {
    en: [
        {
            id: 1,
            item: "WD",
            img: "imgs/travel.png",
            heading: "Travel Website (Demo)",
            text: "Explore the features of this beautiful travel website, designed to enhance user experience and offer seamless navigation.",
            link: "https://tarvel-website-lemon.vercel.app/"
        },
        {
            id: 2,
            item: "WD",
            img: "imgs/plant.png",
            heading: "Plants Website (Demo)",
            text: "Discover how to create engaging layouts and interactive features for a botanical-themed website.",
            link: "https://plants-website-topaz.vercel.app/"
        },
        {
            id: 3,
            item: "WD",
            img: "imgs/delivery.png",
            heading: "Delivery Website (Demo)",
            text: "Explore the core functionalities that make a delivery service website efficient and user-friendly.",
            link: "https://delivery-website-2t7zaot6x-thearnabsaha.vercel.app/"
        },
        {
            id: 4,
            item: "WD",
            img: "imgs/headphone.png",
            heading: "Headphones E-Commerce (Demo)",
            text: "Learn about the key design and functionality elements that enhance user experience on an e-commerce site for headphones.",
            link: "https://headphones-website-iota.vercel.app/"
        },
        {
            id: 5,
            item: "WD",
            img: "imgs/halloween.png",
            heading: "Halloween Event Website (Demo)",
            text: "Explore the spooky design and interactive features that capture the essence of Halloween in this dedicated event website.",
            link: "https://halloween-main.vercel.app/"
        },
        {
            id: 6,
            item: "WD",
            img: "imgs/restaurant.png",
            heading: "Restaurant Website (Demo)",
            text: "Dive into the flavorful world of culinary arts with this restaurant website showcasing menu design, booking systems, and user engagement features.",
            link: "https://restaurant-main-five.vercel.app/"
        }
    ],
    de: [
        {
            id: 1,
            item: "WD",
            img: "imgs/travel.png",
            heading: "Reisewebseite (Demo)",
            text: "Erkunden Sie die Funktionen dieser schönen Reisewebseite, die darauf ausgelegt ist, das Benutzererlebnis zu verbessern und eine nahtlose Navigation zu bieten.",
            link: "https://tarvel-website-lemon.vercel.app/"
        },
        {
            id: 2,
            item: "WD",
            img: "imgs/plant.png",
            heading: "Pflanzenwebseite (Demo)",
            text: "Entdecken Sie, wie Sie ansprechende Layouts und interaktive Funktionen für eine botanisch thematisierte Webseite erstellen.",
            link: "https://plants-website-topaz.vercel.app/"
        },
        {
            id: 3,
            item: "WD",
            img: "imgs/delivery.png",
            heading: "Lieferdienst-Webseite (Demo)",
            text: "Erforschen Sie die Kernfunktionen, die eine Webseite für Lieferdienste effizient und benutzerfreundlich machen.",
            link: "https://delivery-website-2t7zaot6x-thearnabsaha.vercel.app/"
        },
        {
            id: 4,
            item: "WD",
            img: "imgs/headphone.png",
            heading: "Kopfhörer-E-Commerce (Demo)",
            text: "Erfahren Sie mehr über die wichtigen Design- und Funktionsmerkmale, die das Benutzererlebnis auf einer E-Commerce-Seite für Kopfhörer verbessern.",
            link: "https://headphones-website-iota.vercel.app/"
        },
        {
            id: 5,
            item: "WD",
            img: "imgs/halloween.png",
            heading: "Halloween-Eventwebseite (Demo)",
            text: "Erkunden Sie das gruselige Design und die interaktiven Funktionen, die das Wesen von Halloween auf dieser speziellen Veranstaltungswebseite einfangen.",
            link: "https://halloween-main.vercel.app/"
        },
        {
            id: 6,
            item: "WD",
            img: "imgs/restaurant.png",
            heading: "Restaurantwebseite (Demo)",
            text: "Tauchen Sie ein in die geschmackvolle Welt der kulinarischen Künste mit dieser Restaurantwebseite, die Menüdesign, Buchungssysteme und Benutzerinteraktionsfunktionen zeigt.",
            link: "https://restaurant-main-five.vercel.app/"
        }
    ]
};

const staticText = {
    en: {
        heading: "Latest Projects for Clients",
        subtitle: "Helping Brands and Businesses to create Apps and Website With our Expertise",
        viewAll: "View All Projects"
    },
    de: {
        heading: "Neueste Projekte für Kunden",
        subtitle: "Wir helfen Marken und Unternehmen, Apps und Websites mit unserer Expertise zu erstellen",
        viewAll: "Alle Projekte anzeigen"
    }
};

function Project() {
    const { language } = useLanguage(); // Use the language context to get the current language

    const P = project_content[language].map((data) => {
        return <ProjectCard
                    img={data.img}
                    heading={data.heading}
                    text={data.text}
                    id={data.id}
                    key={data.id}
                    link={data.link}
                />
    });

    const goToTop = () => {        
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    
    return (
        <div className="container project__container" style={{justifyContent:"center",display:"flex",marginTop:"2rem"}}>
            <div className="project" >
                <div className="project-heading">
                    <h2>{staticText[language].heading}</h2>
                    <p>{staticText[language].subtitle}</p>
                </div>
                <div className="project-card" >{P}</div>
                <div className="allProj">
                    <Link to="/portfolios" onClick={goToTop} className="project__a">{staticText[language].viewAll}</Link>
                </div>
            </div>
        </div>
    );
}

export default Project;
