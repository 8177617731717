import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLanguage } from "../LanguageContext";  // Import the language context
import { generateLinks } from "../data.js";  // Import the function to generate links

import "./Navbar.css";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

function Navbar() {
    const [isNavShowing, setIsNavShowing] = useState(false);
    const { language, toggleLanguage } = useLanguage();
    const links = generateLinks(language);

    const goToTop = () => {        
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <nav>
            <div className="container nav__container">
                <Link to="/" className="logo" onClick={() => setIsNavShowing(false)}>
                    <img src="../images/Logo.png" alt="logo" />
                </Link>
                <ul className={`nav__links ${isNavShowing ? "show__nav" : "hide__Nav"}`}>
                    {links.map(({ name, path, external }, index) => (
                        <li key={index}>
                            {external ? (
                                <a href={path} onClick={goToTop} target="_blank" rel="noopener noreferrer" className="say_hello SH">
                                    {name}
                                </a>
                            ) : (
                                <NavLink to={path} className={({ isActive }) => isActive ? "active-nav" : ""} onClick={() => {
                                    setIsNavShowing(false);
                                    goToTop();
                                }}>
                                    {name}
                                </NavLink>
                            )}
                        </li>
                    ))}
                </ul>
                {/* <button className="nav__toggle-btn" onClick={() => setIsNavShowing(prev => !prev)}>
                    {isNavShowing ? <MdOutlineClose /> : <FaBars />}
                </button> */}
                                    {/* Toggle Language Button */}
                                    <li>
                        <button onClick={toggleLanguage} className="say_hello SH a">
                            {language === 'en' ? 'DE' : 'EN'}
                        </button>
                    </li>
                <button className="nav__toggle-btn" onClick={() => setIsNavShowing(prev => !prev)}>
                    {isNavShowing ? <MdOutlineClose /> : <FaBars />}
                </button>
            </div>
        </nav>
    );
}


export default Navbar;
