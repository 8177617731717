import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./LanguageContext"; // Import the LanguageProvider
import Home from "./pages/Home/Home";
import About from "./pages/AboutUs/About";
import Contact from "./pages/ContactUs/Contact";
import Portfolios from "./pages/Portfolios/Portfolios";
import Navbar from "./components/Navbar";
import Case from "./pages/CaseStudy/Case";
import TermsAndConditions from "./components/Tnc";
import Faq from "./components/faq";

function App(){
    return (
        <LanguageProvider> {/* Wrap the routes in LanguageProvider */}
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route index element={<Home />}/>
                    <Route path="portfolios" element={<Portfolios />}/>
                    <Route path="about" element={<About />}/>
                    <Route path="contact" element={<Contact />}/>
                    <Route path="case/:heading" element={<Case />}/>
                    <Route path="portfolios/case/:heading" element={<Case />}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/faq" element={<Faq />} />
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    );
};

export default App;
