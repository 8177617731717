// links.js

// Define the base paths for links
const linkPaths = {
    home: "/",
    portfolios: "/portfolios",
    about: "/about",
    contact: "/contact",
    sayHello: "https://wa.me/+916296818891"  // Assuming this is a constant link for messaging
};

// Function to generate links based on language
const generateLinks = (language) => {
    const translations = {
        en: {
            home: "Home",
            portfolios: "Portfolios",
            about: "About",
            contact: "Contact",
            sayHello: "Say Hello"  // English translation
        },
        de: {
            home: "Startseite",
            portfolios: "Portfolios",
            about: "Über uns",
            contact: "Kontakt",
            sayHello: "Sag Hallo"  // German translation
        }
    };

    // Current language translations
    const currentTranslations = translations[language];

    return [
        {
            name: currentTranslations.home,
            path: linkPaths.home,
        },
        {
            name: currentTranslations.portfolios,
            path: linkPaths.portfolios,
        },
        {
            name: currentTranslations.about,
            path: linkPaths.about,
        },
        {
            name: currentTranslations.contact,
            path: linkPaths.contact,
        },
        {
            name: currentTranslations.sayHello,  // This is the dynamically translated label for the contact button
            path: linkPaths.sayHello,
            external: true  // Indicate this is an external link
        },
    ];
};

export { generateLinks, linkPaths };
